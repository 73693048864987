import React from 'react';

import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import {
    KeyboardDatePicker,
} from '@material-ui/pickers';

import { db, firestore } from '../firebase/index';
import moment from 'moment';

import { symptom } from '../data/objects';

class pacientes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data ? { ...props.data.data } : {
                type: "paciente",
                patientName: "",
                patientId: "",
                patientAge: 0,
                patientIdType: "Registro Civil",
                otherPatientIdType: "",
                adultName: "",
                adultId: "",
                adultIdType: "Cédula de Cuidadanía",
                otherAdultIdType: "",
                patientContact: "",
                adultContact: "",
                stillOnQuarantine: "",
                symptoms: [],
                adultNexus: "",
                patientNexus: "",
                signingName: "",
                signingId: "",
            },
            validationErrors: {
                patientName: false,
                patientId: false,
                patientAge: false,
                adultName: false,
                adultId: false,
                patientContact: false,
                adultContact: false,
                signingName: false,
                signingId: false,
            },
            readOnly: props.data ? true : false,
            modalIsVisible: false,
            dataStored: false,
            firebaseError: false,
            selectedDate: moment().set({ 'hour': 12, 'minute': 0, 'second': 0, 'millisecond': 0 }),
        }
    };

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const targetType = e.target.type;
        const checked = e.target.checked

        const symptomDescription = symptom[name];

        let state = { ...this.state };

        if (targetType === "checkbox" && checked === true) {
            state.data.symptoms.push({ description: symptomDescription, person: value });
        } else if (targetType === "checkbox" && checked === false) {
            var newSymptoms = state.data.symptoms.filter(element => element.description !== symptomDescription && element.person !== value);
            state.data.symptoms = newSymptoms;
        } else {
            state.data = { ...state.data, [name]: value }
        }
        this.setState(state);
    };

    handleSubmit = () => {
        if (this.validateRequiredValues()) {
            window.scrollTo(0, 0);
            return;
        }
        this.setState({ modalIsVisible: true, dataStored: false, firebaseError: false });

        var formData = this.state.data;

        const selectedDate = this.state.selectedDate;

        formData['createdDate'] = firestore.Timestamp.fromDate(new Date());
        formData['appointmentDate'] = firestore.Timestamp.fromDate(selectedDate.toDate());
        formData['state'] = "active";

        db.collection('covid-forms-patients')
            .add(formData)
            .then(doc => { this.setState({ dataStored: true }) })
            .catch(error => { this.setState({ firebaseError: true }) })
    };

    validateRequiredValues = () => {
        const data = this.state.data;

        var errors = this.state.validationErrors;
        var oneOrMoreErrors = false;

        if (data.patientName === "") {
            oneOrMoreErrors = true;
            errors.patientName = true;
        };

        if (data.patientAge === 0) {
            oneOrMoreErrors = true;
            errors.patientAge = true;
        };

        if (data.patientId === "") {
            oneOrMoreErrors = true;
            errors.patientId = true;
        };

        if (data.signingName === "") {
            oneOrMoreErrors = true;
            errors.signingName = true;
        };

        if (data.signingId === "") {
            oneOrMoreErrors = true;
            errors.signingId = true;
        };

        if (data.patientContact === "") {
            oneOrMoreErrors = true;
            errors.patientContact = true;
        };

        if (data.patientNexus === "") {
            oneOrMoreErrors = true;
            errors.patientNexus = true;
        };

        this.setState({ validationErrors: errors });
        return oneOrMoreErrors ? true : false;
    }

    handleAppointmentDateChange = (moment) => {
        this.setState({ selectedDate: moment.set({ 'hour': 12, 'minute': 0, 'second': 0, 'millisecond': 0 }) });
    };

    handleCloseModal = (e, reason) => {
        if (reason !== "backdropClick") {
            this.setState({ modalIsVisible: false });
        }

    }

    render() {
        return (
            <Container >
                <Modal
                    disableEscapeKeyDown
                    open={this.state.modalIsVisible}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                >
                    <Fade in={this.state.modalIsVisible}>
                        <div style={{ background: "white" }}>
                            {
                                this.state.firebaseError && <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', padding: 10, margin: 10 }} onClick={this.handleCloseModal}>
                                    <CloseIcon color="error" fontSize="large" />
                                </div>
                            }
                            {
                                !this.state.dataStored ?
                                    <div style={{ padding: 25, margin: 25 }}>
                                        <h3>
                                            Guardando
                                        </h3>
                                        <CircularProgress />
                                    </div>
                                    :
                                    <div style={{ padding: 25, margin: 25 }}>
                                        <h3>
                                            Datos guardados exitosamente, te esperamos!
                                        </h3>
                                        {
                                            document.referrer && document.referrer.toString().includes("/listado") &&
                                            <div>
                                                <a href="/listado">
                                                    Listado
                                                </a>

                                            </div>
                                        }
                                        <a href="https://progresar.com.co">
                                            Volver a progresar.com.co
                                        </a>
                                    </div>
                            }
                        </div>
                    </Fade>
                </Modal>
                {
                    !this.props.data &&
                    <div>
                        <div style={{ textAlign: "center" }}>
                            <h1>Formulario para Pacientes/Acompañantes</h1>
                        </div>

                        <Box m={3}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="DD/MM/yyyy"
                                margin="normal"
                                name="appointmentDate"
                                label="Fecha de la cita"
                                value={this.state.selectedDate}
                                onChange={date => this.handleAppointmentDateChange(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Box>
                    </div>

                }
                <Box m={3}>
                    <h4>Paciente</h4>
                    <Grid container spacing={3}>
                        <Grid item md={3} xs={12}>
                            <TextField disabled={this.state.readOnly} helperText="Requerido" fullWidth name="patientName" label="Nombre del PACIENTE" variant="outlined" error={this.state.validationErrors.patientName} value={this.state.data.patientName} onChange={this.handleChange} />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextField disabled={this.state.readOnly}  helperText="Requerido" fullWidth name="patientId" label="Número de Identificación" variant="outlined" error={this.state.validationErrors.patientId} value={this.state.data.patientId} onChange={this.handleChange} />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextField disabled={this.state.readOnly}  helperText="Requerido" fullWidth name="patientAge" label="Edad" variant="outlined" type="number" error={this.state.validationErrors.patientAge} value={this.state.data.patientAge} onChange={this.handleChange} />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormControl disabled={this.state.readOnly} fullWidth>
                                <InputLabel>Tipo de documento</InputLabel>
                                <Select
                                    labelId="patientIdType"
                                    id="patientIdType"
                                    name="patientIdType"
                                    onChange={this.handleChange}
                                    value={this.state.data.patientIdType}
                                >
                                    <MenuItem value="Registro Civil">Registro Civil</MenuItem>
                                    <MenuItem value="Tarjeta de Identidad">Tarjeta de Identidad</MenuItem>
                                    <MenuItem value="Cédula de Cuidadanía">Cédula de Cuidadanía</MenuItem>
                                    <MenuItem value="Pasaporte">Pasaporte</MenuItem>
                                    <MenuItem value="Permiso de Permanencia">Permiso de Permanencia</MenuItem>
                                    <MenuItem value="NIUP">NIUP</MenuItem>
                                    <MenuItem value="Cédula de Extranjería">Cédula de Extranjería</MenuItem>
                                    <MenuItem value="Otro">Otro</MenuItem>
                                </Select>
                                {
                                    this.state.data.patientIdType === "Otro" &&
                                    <TextField disabled={this.state.readOnly}  name="otherPatientIdType" required placeholder="Otro" value={this.state.data.otherPatientIdType} onChange={this.handleChange} />
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box m={3}>
                    <h4>Acompañante</h4>
                    {this.props.data && this.props.data.data.adultName === "" && <h5 style={{ color: 'red' }}>Sin acompañante</h5>}
                    <Grid container spacing={3}>
                        <Grid item md={4} xs={12}>
                            <TextField disabled={this.state.readOnly}  fullWidth name="adultName" label="Nombre del ACOMPANANTE" variant="outlined" value={this.state.data.adultName} onChange={this.handleChange} />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField disabled={this.state.readOnly}  fullWidth name="adultId" label="Número de Identificación" variant="outlined" value={this.state.data.adultId} onChange={this.handleChange} />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl  disabled={this.state.readOnly}  fullWidth>
                                <InputLabel>Tipo de documento del acompañante</InputLabel>
                                <Select
                                    labelId="adultIdType"
                                    id="adultIdType"
                                    name="adultIdType"
                                    onChange={this.handleChange}
                                    value={this.state.data.adultIdType}
                                >
                                    <MenuItem value="Cédula de Cuidadanía">Cédula de Cuidadanía</MenuItem>
                                    <MenuItem value="Pasaporte">Pasaporte</MenuItem>
                                    <MenuItem value="Permiso de Permanencia">Permiso de Permanencia</MenuItem>
                                    <MenuItem value="Cédula de Extranjería">Cédula de Extranjería</MenuItem>
                                    <MenuItem value="Otro">Otro</MenuItem>
                                </Select>
                                {
                                    this.state.data.adultIdType === "Otro" &&
                                    <TextField disabled={this.state.readOnly}  name="otherAdultIdType" required placeholder="Otro" value={this.state.data.otherAdultIdType} onChange={this.handleChange} />
                                }
                            </FormControl>
                        </Grid>
                    </Grid>

                </Box>
                <Card variant="outlined" m={2}>
                    <CardContent>
                        <h4>
                            ¿Ha presentado la enfermedad COVID-19, o ha tenido contacto con personas que hayan tenido tos, dificultad para respirar, fiebre o decaimiento en los últimos 14 días?
                        </h4>
                        <FormControl disabled={this.state.readOnly}  component="fieldset" error={this.state.validationErrors.patientContact}>
                            <FormLabel component="legend">Paciente</FormLabel>
                            <RadioGroup row aria-label="patientContact" name="patientContact" defaultValue="top" value={this.state.data.patientContact} onChange={this.handleChange}>
                                <FormControlLabel
                                    value="Si"
                                    control={<Radio color="primary" />}
                                    label="Si"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="No"
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="top"
                                />
                            </RadioGroup>
                        </FormControl>
                        <FormControl disabled={this.state.readOnly}  component="fieldset">
                            <FormLabel component="legend">Acompañante</FormLabel>
                            <RadioGroup row aria-label="adultContact" name="adultContact" defaultValue="top" value={this.state.data.adultContact} onChange={this.handleChange}>
                                <FormControlLabel
                                    value="Si"
                                    control={<Radio color="primary" />}
                                    label="Si"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="No"
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="top"
                                />
                            </RadioGroup>
                        </FormControl>
                        {
                            (this.state.data.adultContact === "Si" || this.state.data.patientContact === "Si") &&
                            <div>
                                <FormControl disabled={this.state.readOnly}  component="fieldset">
                                    <FormLabel component="legend">Sigue usted en cuarentena?</FormLabel>
                                    <RadioGroup row aria-label="stillOnQuarantine" name="stillOnQuarantine" defaultValue="top" value={this.state.data.stillOnQuarantine} onChange={this.handleChange}>
                                        <FormControlLabel
                                            value="Si"
                                            control={<Radio color="primary" />}
                                            label="Si"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio color="primary" />}
                                            label="No"
                                            labelPlacement="top"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        }
                    </CardContent>
                </Card>
                <Box m={3}>
                    <h4>
                        ¿Presenta usted algunos de los siguientes síntomas?: (Seleccione la casilla si es afirmativo)
                    </h4>
                    {
                        this.props.data ?
                            (this.props.data.data.symptoms.length > 0 ?
                                this.props.data.data.symptoms.map(element => (
                                    <p>{element.description + ": "} <b>{element.person}</b></p>
                                ))
                                :
                                <p>No reporta síntomas</p>)
                            :

                            <Grid container spacing={3}>
                                <Grid item md={4} xs={12}>
                                    <FormControl disabled={this.state.readOnly}  component="fieldset">
                                        <FormLabel component="legend">Fiebre igual o mayor a 37°C</FormLabel>
                                        <FormGroup row >
                                            <FormControlLabel
                                                value="Paciente"
                                                control={<Checkbox color="primary" />}
                                                label="Paciente"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom1"
                                            />
                                            <FormControlLabel
                                                value="Acompañante"
                                                control={<Checkbox color="primary" />}
                                                label="Acompañante"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom1"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <FormControl disabled={this.state.readOnly}  component="fieldset">
                                        <FormLabel component="legend">Dolor de garganta, congestión nasal, moco, tos o cualquier otro signo respiratorio</FormLabel>
                                        <FormGroup aria-label="symptom2" name="symptom2" row>
                                            <FormControlLabel
                                                value="Paciente"
                                                control={<Checkbox color="primary" />}
                                                label="Paciente"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom2"
                                            />
                                            <FormControlLabel
                                                value="Acompañante"
                                                control={<Checkbox color="primary" />}
                                                label="Acompañante"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom2"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <FormControl disabled={this.state.readOnly}  component="fieldset">
                                        <FormLabel component="legend">Sensación de cansancio o malestar</FormLabel>
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="Paciente"
                                                control={<Checkbox color="primary" />}
                                                label="Paciente"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom3"
                                            />
                                            <FormControlLabel
                                                value="Acompañante"
                                                control={<Checkbox color="primary" />}
                                                label="Acompañante"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom3"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <FormControl disabled={this.state.readOnly}  component="fieldset">
                                        <FormLabel component="legend">Malestar digestivo</FormLabel>
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="Paciente"
                                                control={<Checkbox color="primary" />}
                                                label="Paciente"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom4"
                                            />
                                            <FormControlLabel
                                                value="Acompañante"
                                                control={<Checkbox color="primary" />}
                                                label="Acompañante"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom4"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <FormControl disabled={this.state.readOnly}  component="fieldset">
                                        <FormLabel component="legend">Perdida de olfato o del gusto</FormLabel>
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="Paciente"
                                                control={<Checkbox color="primary" />}
                                                label="Paciente"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom5"
                                            />
                                            <FormControlLabel
                                                value="Acompañante"
                                                control={<Checkbox color="primary" />}
                                                label="Acompañante"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom5"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <FormControl disabled={this.state.readOnly}  component="fieldset">
                                        <FormLabel component="legend">Algún tipo de lesión en la piel, en dedos o manos que nunca haya tenido (brote, salpullido, entre otros)</FormLabel>
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="Paciente"
                                                control={<Checkbox color="primary" />}
                                                label="Paciente"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom6"
                                            />
                                            <FormControlLabel
                                                value="Acompañante"
                                                control={<Checkbox color="primary" />}
                                                label="Acompañante"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom7"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <FormControl disabled={this.state.readOnly}  component="fieldset">
                                        <FormLabel component="legend">Otro</FormLabel>
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="Paciente"
                                                control={<Checkbox color="primary" />}
                                                label="Paciente"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom6"
                                            />
                                            <FormControlLabel
                                                value="Acompañante"
                                                control={<Checkbox color="primary" />}
                                                label="Acompañante"
                                                labelPlacement="top"
                                                onChange={this.handleChange}
                                                name="symptom6"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                    }

                </Box>
                <Card variant="outlined">
                    <CardContent>
                        <h4>NEXO EPIDEMIOLÓGICO: Ha estado en contacto laboral, social o familiar con alguna persona sospechosa o confirmada de covid-19 (Coronavirus)</h4>
                        <FormControl disabled={this.state.readOnly}  component="fieldset" error={this.state.validationErrors.patientNexus}>
                            <FormLabel component="legend">Paciente</FormLabel>
                            <RadioGroup row aria-label="patientNexus" name="patientNexus" defaultValue="top" value={this.state.data.patientNexus} onChange={this.handleChange}>
                                <FormControlLabel
                                    value="Si"
                                    control={<Radio color="primary" />}
                                    label="Si"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="No"
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="top"
                                />
                            </RadioGroup>
                        </FormControl>
                        <FormControl disabled={this.state.readOnly}  component="fieldset">
                            <FormLabel component="legend">Acompañante</FormLabel>
                            <RadioGroup row aria-label="adultNexus" name="adultNexus" defaultValue="top" value={this.state.data.adultNexus} onChange={this.handleChange}>
                                <FormControlLabel
                                    value="Si"
                                    control={<Radio color="primary" />}
                                    label="Si"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="No"
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="top"
                                />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                <Box m={3}>
                    <h2>Certificado de veracidad</h2>
                    <p>Certifico que el anterior cuestionario corresponde a mi estado de salud a la fecha de hoy. En caso de contestar SI, en alguno de las preguntas realizadas se debe informar a la coordinación operativa del centro para determinar la conducta a seguir y se postergará la atención hasta pasado el periodo de cuarentena.</p>
                    <p>Firma (escriba el nombre completo del responsable o representante del paciente como aceptación)</p>
                    <TextField disabled={this.state.readOnly}  helperText="Requerido" fullWidth name="signingName" label="Nombre completo" variant="outlined" error={this.state.validationErrors.signingName} value={this.state.data.signingName} onChange={this.handleChange} />
                    <p>Número de Identificación</p>
                    <TextField disabled={this.state.readOnly}  helperText="Requerido" fullWidth name="signingId" label="Número de Identificación" variant="outlined" error={this.state.validationErrors.signingId} value={this.state.data.signingId} onChange={this.handleChange} />
                </Box>
                {
                    !this.props.data &&
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 35 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            startIcon={<SaveIcon />}
                            onClick={this.handleSubmit}
                        >
                            Guardar
                        </Button>
                    </div>

                }
            </Container >
        )
    }
}

export default pacientes;