const symptom = {
    symptom1: "Fiebre igual o mayor a 37°C",
    symptom2: "Dolor de garganta, congestión nasal, moco, tos o cualquier otro signo respiratorio",
    symptom3: "Sensación de cansancio o malestar",
    symptom4: "Malestar digestivo",
    symptom5: "Perdida de olfato o del gusto",
    symptom6: "Algún tipo de lesión en la piel, en dedos o manos que nunca haya tenido (brote, salpullido, entre otros)",
    symptom7: "Otro",
}

export { symptom };