import React from 'react';
import { Card, CardContent, CardHeader, Container, TextField, Button, Box } from '@material-ui/core';

import { auth } from '../firebase';

class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            username: "",
            password: "",
            errorCode: "",
            errorMessage: ""
        };
    };

    onClickHandler = () => {
        const username = this.state.username;
        const password = this.state.password;

        auth.signInWithEmailAndPassword(username, password)
            .then(credential => {
                console.log('Logged in: ', credential.user);
            })
            .catch(error => this.setState({ errorCode: error.code, errorMessage: error.message }));
    };

    onChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render() {
        const { errorMessage } = this.state;
        return (
            <Container>
                <Box m={3} p={3}>
                    <Card variant="outlined">
                        <CardHeader subheader="Identifíquese para ingresar" />
                        <CardContent>
                            <Box m={3}>
                                {
                                    errorMessage !== "" && <span style={{color: "red"}}>· Credenciales incorrectas</span>
                                }
                            </Box>

                            <Box m={3}>

                                <TextField fullWidth type="email" name="username" label="Correo" variant="outlined" onChange={this.onChangeHandler} />
                            </Box>
                            <Box m={3}>
                                <TextField fullWidth name="password" label="Contraseña" type="password" variant="outlined" onChange={this.onChangeHandler} />
                            </Box>
                            <Box m={3}>
                                <Button size="large" variant="outlined" color="primary" onClick={this.onClickHandler}>
                                    Ingresar
                                </Button>
                            </Box>

                        </CardContent>
                    </Card>
                </Box>

            </Container>
        );
    }
}

export default Login;