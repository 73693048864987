import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

firebase.initializeApp({
  apiKey: "AIzaSyCNv_IFCwvVV5RyUy5naKoxpGpQbxC77Hw",
  authDomain: "progresar-e118f.firebaseapp.com",
  projectId: "progresar-e118f",
  storageBucket: "progresar-e118f.appspot.com",
  messagingSenderId: "954809239591",
  appId: "1:954809239591:web:33ed0c02998b3ba9fde602"
});

var db = firebase.firestore();
var auth = firebase.auth();
var firestore = firebase.firestore;

export { db, auth, firestore }