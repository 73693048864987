import React from 'react';

import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { Container } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { db, firestore, auth } from '../firebase/index';
import moment from 'moment';

import Patient from './patient';
import Visitor from './visitor';
import Employee from './employee';
import Login from '../components/login';

class listado extends React.Component {
    constructor() {
        super();
        this.authUnsubscribe = null;
        this.patientsDBUnsubscribe = null;
        this.visitorsDBUnsubscribe = null;
        this.employeesDBUnsubscribe = null;
        this.state = {
            patientForms: [],
            visitorForms: [],
            employeeForms: [],
            selectedDate: moment().set({ 'hour': 12, 'minute': 0, 'second': 0, 'millisecond': 0 }),
            selectedForm: {
                data: {
                    type: "",
                    patientName: "",
                    patientId: "",
                    patientAge: 0,
                    patientIdType: "",
                    otherPatientIdType: "",
                    adultName: "",
                    adultId: "",
                    adultIdType: "",
                    otherAdultIdType: "",
                    patientContact: "",
                    adultContact: "",
                    stillOnQuarantine: "",
                    symptoms: [],
                    adultNexus: "",
                    patientNexus: "",
                    signingName: "",
                    signingId: "",
                },
                id: "",
            },
            user: null,
            modalIsVisible: false,
            deleteModalIsVisible: false,
        }
    };

    componentWillUnmount = () => {
        this.authUnsubscribe();
        this.visitorsDBUnsubscribe();
        this.employeesDBUnsubscribe();
        this.patientsDBUnsubscribe();
    };

    componentDidMount = () => {
        this.authUnsubscribe = auth.onAuthStateChanged(user => {
            if (user !== null) {
                var date = this.state.selectedDate;
                this.fetchAppointments(date);
            }
            this.setState({ user });
        });

    };

    fetchAppointments = (date) => {

        var selectedDate = date.toDate();

        this.patientsDBUnsubscribe = db.collection('covid-forms-patients')
            .where('appointmentDate', '==', firestore.Timestamp.fromDate(selectedDate))
            .where('state', "==", 'active')
            .onSnapshot(snapshot => {
                var patientForms = [];
                
                snapshot.forEach(element => {
                    patientForms.push({ data: element.data(), id: element.id });
                });
                this.setState({ patientForms });
            });

        this.visitorsDBUnsubscribe = db.collection('covid-forms-visitors')
            .where('appointmentDate', '==', firestore.Timestamp.fromDate(selectedDate))
            .where('state', "==", 'active')
            .onSnapshot(snapshot => {
                var visitorForms = [];
                snapshot.forEach(element => {
                    visitorForms.push({ data: element.data(), id: element.id });
                });
                this.setState({ visitorForms });
            });

        this.employeesDBUnsubscribed = db.collection('covid-forms-employees')
            .where('appointmentDate', '==', firestore.Timestamp.fromDate(selectedDate))
            .where('state', "==", 'active')
            .onSnapshot(snapshot => {
                var employeeForms = [];
                snapshot.forEach(element => {
                    employeeForms.push({ data: element.data(), id: element.id });
                });
                this.setState({ employeeForms });
            });
    }

    handleDateChange = (date) => {
        date.set({ 'hour': 12, 'minute': 0, 'second': 0, 'millisecond': 0 });
        this.fetchAppointments(date);
        this.setState({ selectedDate: date });
    };

    handleFormClick = (form) => {
        this.setState({ selectedForm: form, modalIsVisible: true });
    };

    handleCloseModal = () => {
        this.setState({ modalIsVisible: false, deleteModalIsVisible: false });
    };

    handleOpenDeleteModal = () => {
        this.setState({ deleteModalIsVisible: true });
    };

    handleDeleteForm = () => {
        const formId = this.state.selectedForm.id;
        const formType = this.state.selectedForm.data.type;

        var collectionName;

        if (formType === "paciente") {
            collectionName = "covid-forms-patients";
        } else if (formType === "visitante") {
            collectionName = "covid-forms-visitors";
        } else if (formType === "empleado") {
            collectionName = "covid-forms-employees";
        }

        db.collection(collectionName)
            .doc(formId)
            .update({ state: "deleted" })
            .then(doc => this.setState({ modalIsVisible: false, deleteModalIsVisible: false }))
            .catch(error => window.alert(error));
    }

    render() {
        const { employeeForms, visitorForms, patientForms, selectedForm, modalIsVisible, deleteModalIsVisible, selectedDate, user } = this.state;

        if (user === null) {
            return (<Login />)
        };

        return (
            <Container>
                <Modal
                    open={deleteModalIsVisible}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Container style={{ backgroundColor: "white", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <h1>Desea eliminar este formulario?</h1>
                        <div>
                            <Button variant="outlined" style={{margin: 10}} color="secondary" size="large" onClick={this.handleDeleteForm} >
                                Si
                            </Button>
                            <Button variant="outlined" style={{margin: 10}} color="primary" size="large" onClick={this.handleCloseModal}>
                                No
                            </Button>
                        </div>

                    </Container>
                </Modal>
                <Modal
                    open={modalIsVisible}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    style={{ overflow: 'scroll' }}
                >
                    <Fade in={modalIsVisible}>
                        <div style={{ background: "#dedede" }}>
                            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', padding: 10, margin: 10 }} >
                                <div style={{ margin: 5, color: "grey" }}><DeleteIcon fontSize="large" onClick={this.handleOpenDeleteModal} /></div>
                                <div style={{ margin: 5 }}>
                                    <CloseIcon color="error" fontSize="large" onClick={this.handleCloseModal} />
                                </div>
                            </div>
                            {selectedForm.data.type === "paciente" && <Patient data={selectedForm} />}
                            {selectedForm.data.type === "visitante" && <Visitor data={selectedForm} />}
                            {selectedForm.data.type === "empleado" && <Employee data={selectedForm} type="" />}
                        </div>
                    </Fade>
                </Modal>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ textAlign: "center" }}><h1>Lista de Formularios</h1></Grid>
                    <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="DD/MM/yyyy"
                            margin="normal"
                            label="Fecha"
                            value={selectedDate}
                            onChange={date => this.handleDateChange(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <h3>Pacientes
                            <Button variant="outlined" color="primary" size="small" startIcon={<AddIcon />} href="/pacientes">
                                Crear nuevo
                            </Button>
                        </h3>

                        <div>
                            {
                                patientForms.length > 0 ? patientForms.map((element, index) => (
                                    <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 10, margin: 5, borderRadius: 5, borderColor: "grey", borderStyle: "solid", borderWidth: 0.5 }} onClick={() => this.handleFormClick(element)}>
                                        <span style={{ margin: 3 }}>{element.data.patientName}</span>
                                        <Chip label="Ver" size="small" />
                                    </div>
                                ))
                                    :
                                    <span>No hay formularios para esta fecha</span>
                            }
                        </div>
                    </Grid>
                    <Grid item md={4}>
                        <h3>Empleados
                            <Button variant="outlined" color="primary" size="small" startIcon={<AddIcon />} href="/empleados">
                                Crear nuevo
                            </Button>
                        </h3>
                        <div>
                            {
                                employeeForms.length > 0 ? employeeForms.map((element, index) => (
                                    <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 10, margin: 5, borderRadius: 5, borderColor: "grey", borderStyle: "solid", borderWidth: 0.5 }} onClick={() => this.handleFormClick(element)}>
                                        <span style={{ margin: 3 }}>{element.data.employeeName}</span>
                                        <Chip label="Ver" size="small" />
                                    </div>
                                ))
                                    :
                                    <span>No hay formularios para esta fecha</span>
                            }
                        </div>
                    </Grid>
                    <Grid item md={4}>
                        <h3>Visitantes
                            <Button variant="outlined" color="primary" size="small" startIcon={<AddIcon />} href="/visitantes">
                                Crear nuevo
                            </Button>
                        </h3>
                        <div>
                            {
                                visitorForms.length > 0 ? visitorForms.map((element, index) => (
                                    <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 10, margin: 5, borderRadius: 5, borderColor: "grey", borderStyle: "solid", borderWidth: 0.5 }} onClick={() => this.handleFormClick(element)}>
                                        <span style={{ margin: 3 }}>{element.data.visitorName + " - " + element.data.visitorCompany}</span>
                                        <Chip label="Ver" size="small" />
                                    </div>
                                ))
                                    :
                                    <span>No hay formularios para esta fecha</span>
                            }
                        </div>
                    </Grid>
                </Grid>

            </Container>
        );
    }
}

export default listado;