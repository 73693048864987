import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import "moment/locale/es";

// Routes
import List from './routes/listado';
import Patient from './routes/patient'
import Employee from './routes/employee';
import Visitor from './routes/visitor';
import moment from 'moment';

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale("es")}>
    <Router>
      <Switch>
        <Route path='/empleados' component={Employee}/>
        <Route path='/visitantes' component={Visitor}/>
        <Route path='/pacientes' component={Patient}/>
        <Route path='/listado' component={List}/>
      </Switch>
    </Router>
    </MuiPickersUtilsProvider>,
  document.getElementById('root')
);


