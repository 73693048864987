import React from 'react';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import {
    KeyboardDatePicker,
} from '@material-ui/pickers';

import { db, firestore } from '../firebase/index';
import moment from 'moment';

export default class Employee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data ? { ...props.data.data } : {
                type: "empleado",
                employeeName: "",
                employeeId: "",
                fever: false,
                cough: false,
                tiredness: false,
                skin: false,
                throught: false,
                breathing: false,
                diarrhea: false,
                taste: false,
                nasal: false,
                pain: false,
                digest: false,
                smell: false,
                otherSymptom: "",
                beforeEating: false,
                afterEating: false,
                afterBathroom: false,
                afterMoney: false,
                afterCoughSneeze: false,
                beforeTouchingFace: false,
                beforeCleaning: false,
                afterCleaning: false,
                afterTouchingWorkArea: false,
                handSanitizer: false,
                startingTemperature: 0,
            },
            validationErrors: {
                employeeName: false,
                employeeId: false,
                employeeContact: false,
                adultContact: false,
                signingName: false,
                signingId: false,
                startingTemperature: false,
            },
            readOnly: document.URL.toString().includes("/listado") ? true : false,
            modalIsVisible: false,
            dataStored: false,
            firebaseError: false,
            firebaseErrorMessage: "",
            selectedDate: moment().set({ 'hour': 12, 'minute': 0, 'second': 0, 'millisecond': 0 }),
        }
    }

    handleAppointmentDateChange = (moment) => {
        this.setState({ selectedDate: moment.set({ 'hour': 12, 'minute': 0, 'second': 0, 'millisecond': 0 }) });
    };

    handleChange = (e) => {
        const name = e.target.name;
        var value = e.target.value;
        const targetType = e.target.type;
        const checked = e.target.checked


        let state = { ...this.state };

        if (targetType === "checkbox") {
            value = checked
        }

        state.data = { ...state.data, [name]: value }

        this.setState(state);
    };

    handleSubmit = () => {

        if (this.validateRequiredValues()) {
            window.scrollTo(0, 0);
            return;
        }
        this.setState({ modalIsVisible: true, dataStored: false, firebaseError: false });

        var formData = this.state.data;

        const selectedDate = this.state.selectedDate;
        
        formData['appointmentDate'] = firestore.Timestamp.fromDate(selectedDate.toDate());
        formData['state'] = "active";
        formData['createdDate'] = firestore.Timestamp.fromDate(new Date());

        db.collection('covid-forms-employees')
        .add(formData)
        .then(doc => { this.setState({ dataStored: true }) })
        .catch(error => { console.log(error); this.setState({firebaseErrorMessage: error, firebaseError: true }) })
        
    };

    validateRequiredValues = () => {
        const data = this.state.data;

        var errors = this.state.validationErrors;
        var oneOrMoreErrors = false;

        if (data.employeeName === "") {
            oneOrMoreErrors = true;
            errors.employeeName = true;
        };

        if (data.employeeId === "") {
            oneOrMoreErrors = true;
            errors.employeeId = true;
        };

        if (data.startingTemperature === 0) {
            oneOrMoreErrors = true;
            errors.startingTemperature = true;
        };

        this.setState({ validationErrors: errors });
        return oneOrMoreErrors ? true : false;
    }

    handleCloseModal = (e, reason) => {
        if (reason !== "backdropClick") {
            this.setState({ modalIsVisible: false });
        }
    }

    render() {
        return (
            <Container>
                <Modal
                    disableEscapeKeyDown
                    open={this.state.modalIsVisible}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                >
                    <Fade in={this.state.modalIsVisible}>
                        <div style={{ background: "white" }}>
                            {
                                this.state.firebaseError && <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', padding: 10, margin: 10 }} onClick={this.handleCloseModal}>
                                    <h3>Error</h3>
                                    <p>{this.state.firebaseErrorMessage}</p>
                                    <CloseIcon color="error" fontSize="large" />
                                </div>
                            }
                            {
                                !this.state.dataStored ?
                                    <div style={{ padding: 25, margin: 25 }}>
                                        <h3>
                                            Guardando
                                        </h3>
                                        <CircularProgress />
                                    </div>
                                    :
                                    <div style={{ padding: 25, margin: 25 }}>
                                        <h3>
                                            Datos guardados exitosamente!
                                        </h3>
                                        {
                                            document.referrer && document.referrer.toString().includes("/listado") &&
                                            <div>
                                                <a href="/listado">
                                                    Listado
                                                </a>

                                            </div>
                                        }
                                        <a href="https://progresar.com.co">
                                            Volver a progresar.com.co
                                        </a>
                                    </div>
                            }
                        </div>
                    </Fade>
                </Modal>
                {
                    !this.props.data && <div>
                        <div style={{ textAlign: "center" }}>
                            <h1>Formulario para Empleados</h1>
                        </div>
                        <Box m={3}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="DD/MM/yyyy"
                                margin="normal"
                                name="appointmentDate"
                                label="Fecha de visita"
                                value={this.state.selectedDate}
                                onChange={date => this.handleAppointmentDateChange(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Box>
                    </div>
                }
                <Box m={3}>
                    <h4>Empleado</h4>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField disabled={this.state.readOnly} helperText="Requerido" fullWidth name="employeeName" label="Nombre del Empleado" variant="outlined" error={this.state.validationErrors.employeeName} value={this.state.data.employeeName} onChange={this.handleChange} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField disabled={this.state.readOnly}   helperText="Requerido" fullWidth name="employeeId" label="Número de Identificación" variant="outlined" error={this.state.validationErrors.employeeId} value={this.state.data.employeeId} onChange={this.handleChange} />
                        </Grid>
                    </Grid>
                </Box>
                <Card variant="outlined" m={2}>
                    <CardContent>
                        <h4>¿Ha usted presentado alguno de los siguientes síntomas?</h4>
                        <FormControl disabled={this.state.readOnly} component="fieldset">
                            <FormGroup aria-label="position">
                                <FormControlLabel
                                    checked={this.state.data.fever}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="fever"
                                    control={<Checkbox color="primary" />}
                                    label="Fiebre"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.cough}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="cough"
                                    control={<Checkbox color="primary" />}
                                    label="Tos"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.tiredness}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="tiredness"
                                    control={<Checkbox color="primary" />}
                                    label="Fatiga"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.skin}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="skin"
                                    control={<Checkbox color="primary" />}
                                    label="Lesiones de Piel"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.throught}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="throught"
                                    control={<Checkbox color="primary" />}
                                    label="Dolor de Garganta"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.breathing}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="breathing"
                                    control={<Checkbox color="primary" />}
                                    label="Dificultad Respiratoria"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.diarrhea}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="diarrhea"
                                    control={<Checkbox color="primary" />}
                                    label="Diarrea"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.taste}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="taste"
                                    control={<Checkbox color="primary" />}
                                    label="Perdida del Sentido del Gusto"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.nasal}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="nasal"
                                    control={<Checkbox color="primary" />}
                                    label="Congestión Nasal"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.pain}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="pain"
                                    control={<Checkbox color="primary" />}
                                    label="Dolor Muscular"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.digest}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="digest"
                                    control={<Checkbox color="primary" />}
                                    label="Molestias Digestivas"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.smell}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="smell"
                                    control={<Checkbox color="primary" />}
                                    label="Perdida del Sentido del Olfato"
                                    labelPlacement="end"
                                />
                                <TextField disabled={this.state.readOnly}  fullWidth name="otherSymptom" label="Otros síntomas" value={this.state.data.otherSymptom} onChange={this.handleChange} />
                            </FormGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                <Box m={3}>
                    <h4>
                        Temperatura
                    </h4>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField disabled={this.state.readOnly} error={this.state.validationErrors.startingTemperature}  helperText="Requerido" fullWidth name="startingTemperature" label="Temperatura del empleado" variant="outlined" value={this.state.data.startingTemperature} onChange={this.handleChange} />
                        </Grid>
                    </Grid>
                </Box>
                <Card variant="outlined">
                    <CardContent>
                    <h4>
                        ¿En que momentos ha realizado el lavado de manos?
                    </h4>
                        <FormControl disabled={this.state.readOnly} component="fieldset">
                            <FormGroup aria-label="position">
                                <FormControlLabel
                                    checked={this.state.data.beforeEating}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="beforeEating"
                                    control={<Checkbox color="primary" />}
                                    label="Antes de Comer"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.afterEating}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="afterEating"
                                    control={<Checkbox color="primary" />}
                                    label="Después de Comer"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.afterBathroom}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="afterBathroom"
                                    control={<Checkbox color="primary" />}
                                    label="Después de ir al Baño"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.afterMoney}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="afterMoney"
                                    control={<Checkbox color="primary" />}
                                    label="Después de Manipular Dinero"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.afterCoughSneeze}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="afterCoughSneeze"
                                    control={<Checkbox color="primary" />}
                                    label="Después de Estornudar o Toser"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.beforeTouchingFace}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="beforeTouchingFace"
                                    control={<Checkbox color="primary" />}
                                    label="Antes de Tocarme la Cara"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.beforeCleaning}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="beforeCleaning"
                                    control={<Checkbox color="primary" />}
                                    label="Antes de Limpiar y Desinfectar mi Área de Trabajo"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.afterCleaning}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="afterCleaning"
                                    control={<Checkbox color="primary" />}
                                    label="Después de Limpiar y Desinfectar mi Área de Trabajo"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.afterTouchingWorkArea}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="afterTouchingWorkArea"
                                    control={<Checkbox color="primary" />}
                                    label="Después de estar en Contacto con Superficie de Contacto Frecuente"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    checked={this.state.data.handSanitizer}
                                    onChange={this.handleChange}
                                    value={true}
                                    name="handSanitizer"
                                    control={<Checkbox color="primary" />}
                                    label="Cuento con Solución Desinfectante Hidroalcohólica"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </CardContent>
                </Card>
                    {
                        this.props.type !== "" &&
                        <div style={{ display: 'flex', justifyContent: 'center', padding: 35 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            startIcon={<SaveIcon />}
                            onClick={this.handleSubmit}
                        >
                            Guardar
                        </Button>
                    </div>}
            </Container>
        );
    }
}